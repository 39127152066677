import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {Tax} from '../tax'
import {WixFee} from '../wix-fee'
import {getFlexJustifyContentClass} from '../../../alignment'
import s from './price.scss'
import {PriceProps} from './index'

export const Price = ({t, className, ticket, isMobile, contentAlignment}: PriceProps) => {
  const price = getFormattedMoney(ticket.price)

  return (
    <div className={className} role="cell">
      <p data-hook="price" aria-label={t('mobile.thanks.price', {price})}>
        {price}
      </p>
      <div
        className={classNames({
          [s.taxFeeContainer]: isMobile,
          [getFlexJustifyContentClass(contentAlignment)]: isMobile,
        })}
      >
        <Tax ticket={ticket} />
        <WixFee ticket={ticket} />
      </div>
    </div>
  )
}

import * as React from 'react'
import Close from 'wix-ui-icons-common/on-stage/Close'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {IconButton} from 'wix-ui-tpa/IconButton'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {classes} from './close-button.st.css'
import {CloseButtonProps} from './index'

export const CloseButton = ({closeButtonAsButton, closeModal, className}: CloseButtonProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  return (
    <IconButton
      aria-label={t('a11y.close')}
      data-hook={DH.CLOSE_BUTTON}
      className={classNames(classes.closeButtonX, className, {
        [classes.asButton]: closeButtonAsButton,
        [classes.desktop]: !isMobile,
      })}
      onClick={() => closeModal()}
      tabIndex={0}
      icon={<Close />}
    />
  )
}

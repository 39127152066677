import * as React from 'react'
import {
  BI_ORIGINS,
  focusElement,
  hookToAttributeSelector,
  PlaceWithTicketInfo,
  saleScheduled,
} from '@wix/wix-events-commons-statics'
import {Pagination} from 'wix-ui-tpa/Pagination'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {TicketsListContainer} from '../../tickets-list-container'
import {Ticket} from '../../ticket'
import {ERROR} from '../../../../../actions/seating'
import {useColors} from '../../../../../hooks/colors'
import {getTicketById} from '../../../../../selectors/tickets'
import {EmptyState} from './empty-state'
import {classes} from './tickets-list.st.css'
import {TicketsListProps} from './index'

const PLACES_PER_PAGE = 50

export const TicketsList = ({
  places,
  addPlaceToBasket,
  setError,
  ticketLimitReached,
  setForcedPopover,
  resetFilters,
  tickets,
}: TicketsListProps) => {
  const {darkColor} = useColors()
  const [currentPage, setCurrentPage] = React.useState(1)
  const placesCount = places.length

  React.useEffect(() => {
    const list = document.querySelector(hookToAttributeSelector(DH.TICKETS_LIST)) as HTMLElement

    if (list) {
      list.scrollTop = 0
    }

    focusElement({selector: hookToAttributeSelector(DH.TICKETS_COUNT)})
  }, [currentPage])

  const handlePlaceClick = (clickedPlace: PlaceWithTicketInfo, selected: boolean) => {
    const ticket = getTicketById(tickets, clickedPlace.ticketId)

    if (saleScheduled(ticket)) {
      return
    } else if (ticketLimitReached && !selected) {
      setError(ERROR.TICKET_LIMIT_REACHED)
    } else {
      addPlaceToBasket({
        placeId: clickedPlace.id,
        count: selected ? 0 : 1,
        origin: BI_ORIGINS.SEATINGS_MODAL_TICKET_LIST,
      })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const list = event?.currentTarget?.children?.[0] as HTMLElement
    const firstListItem = list?.children?.[0] as HTMLElement
    const lastListItem = list?.children?.[list?.children?.length - 1] as HTMLElement

    if (event.key === 'Home') {
      firstListItem?.querySelector('button')?.focus()
    }

    if (event.key === 'End') {
      lastListItem?.querySelector('button')?.focus()
    }
  }

  return (
    <div className={classes.container} data-hook={DH.TICKETS_LIST} onKeyDown={handleKeyDown}>
      <TicketsListContainer className={!placesCount ? classes.emptyState : undefined}>
        {placesCount ? (
          places
            .slice((currentPage - 1) * PLACES_PER_PAGE, currentPage * PLACES_PER_PAGE)
            .map((place, index) => (
              <Ticket
                key={`${index}-${place.id}`}
                place={place}
                onClick={clickedPlace => handlePlaceClick(clickedPlace, place.inBasket)}
                setForcedPopover={setForcedPopover}
              />
            ))
        ) : (
          <EmptyState onButtonClick={() => resetFilters()} />
        )}
      </TicketsListContainer>
      {placesCount > PLACES_PER_PAGE ? (
        <div className={classes.paginationContainer}>
          <Pagination
            className={classNames(classes.pagination, {[classes.paginationNotWired]: !darkColor})}
            currentPage={currentPage}
            totalPages={Math.ceil(placesCount / PLACES_PER_PAGE)}
            onChange={({page}) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </div>
  )
}

import * as React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../classnames.scss'
import s from './description.scss'
import {DescriptionProps} from './index'

export const Description = ({description}: DescriptionProps) => (
  <div
    className={classNames(s.description, c.evDescriptionFont, c.evDescriptionColor)}
    data-hook={DH.headerEventDescription}
  >
    {description}
  </div>
)

import * as React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import s from './tickets-list-container.scss'
import {TicketsListContainerProps} from './index'

export const TicketsListContainer: React.FC<TicketsListContainerProps> = ({
  chooseSeatMode,
  children,
  className,
  basketView,
}) => {
  const {isMobile} = useEnvironment()

  return (
    <ul
      className={classNames(s.list, className, {
        [s.basketViewDesktop]: basketView && !isMobile,
        [s.mobileList]: isMobile,
        [s.listPaddingTop]: chooseSeatMode,
      })}
    >
      {children}
    </ul>
  )
}

import * as React from 'react'
import {PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import {Tickets} from '../tickets'
import {TicketDescription} from '../ticket-description'
import {Plan} from '../plan'
import {Footer} from '../footer'
import {AccessibilityMode} from '../accessibility-mode'
import {classes} from './mobile.st.css'
import {MobileProps} from './index'

export const Mobile = ({
  placesInBasket,
  selectedPlace,
  onRemoveClick,
  onCheckoutClick,
  onPlaceClick,
  onAddClick,
  onCloseClick,
  showAccessibilityMode,
  placesInBasketCount,
}: MobileProps) => {
  const [showTickets, setShowTickets] = React.useState(false)
  const [selectedSeatForDescription, setSelectedSeatForDescription] = React.useState<PlaceWithTicketInfo>(null)

  return (
    <div className={classNames(classes.container, {[classes.listContainer]: showAccessibilityMode})}>
      <Tickets
        placesInBasketCount={placesInBasketCount}
        showAccessibilityMode={showAccessibilityMode}
        placesInBasket={placesInBasket}
        showTickets={showTickets}
        onBackClick={() => setShowTickets(false)}
        onRemoveClick={onRemoveClick}
        onCheckoutClick={onCheckoutClick}
        onTicketMoreInfoClick={setSelectedSeatForDescription}
        chooseSeatMode={false}
        selectedSeatForDescription={selectedSeatForDescription}
      />
      <TicketDescription
        selectedSeatForDescription={selectedSeatForDescription}
        onClose={() => setSelectedSeatForDescription(null)}
      />
      {showAccessibilityMode ? (
        <AccessibilityMode onFooterButtonClick={() => setShowTickets(true)} />
      ) : (
        <>
          <Plan onPlaceClick={onPlaceClick} forcedPopover={null} />
          <Footer
            withoutBreakdown={true}
            selectedPlace={selectedPlace}
            onAddClick={onAddClick}
            onCloseClick={onCloseClick}
            onCheckoutClick={() => setShowTickets(true)}
          />
        </>
      )}
    </div>
  )
}

import * as React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Text} from 'wix-ui-tpa/Text'
import {useEventDateInformation} from '../../../../../../../commons/hooks/dates'
import {classes} from './event-info.st.css'
import {EventInfoProps} from './index'

export const EventInfo = ({title, location, eventId}: EventInfoProps) => {
  const {isMobile} = useEnvironment()
  const {fullDate} = useEventDateInformation(eventId)

  return (
    <div className={classNames(classes.event, {[classes.desktopEvent]: !isMobile})}>
      <Text tagName="H1" className={classNames(classes.name)} data-hook={DH.EVENT_INFO}>
        {title}
      </Text>
      <Text tagName="p" className={classes.date}>
        {fullDate}, {location}
      </Text>
    </div>
  )
}

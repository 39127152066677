import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {addCircularTabFocus, focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SlideInModal} from '../../slide-in-modal'
import {Header} from './header'
import {Filters} from './filters'
import {classes} from './accessibility-mode.st.css'
import {Footer} from './footer'
import {TicketsCountInfo} from './tickets-count-info'
import {TicketsList} from './tickets-list'
import {AccessibilityModeProps} from './index'

export const AccessibilityMode = ({
  showAccessibilityMode,
  setShowAccessibilityMode,
  onFooterButtonClick,
  placesCount,
  setForcedPopover,
  chooseSeatMode,
  setAccessibilityToast,
}: AccessibilityModeProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()

  React.useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.BACK), canFocus: true})
    addCircularTabFocus(hookToAttributeSelector(DH.BACK), hookToAttributeSelector(DH.CLOSE_BUTTON))
  }, [])

  if (isMobile) {
    return (
      <div className={classes.mobileContainer}>
        <Filters t={t} />
        {placesCount ? <TicketsCountInfo placesCount={placesCount} /> : null}
        <TicketsList />
        <Footer onButtonClick={onFooterButtonClick} />
      </div>
    )
  }

  const onBackClick = () => {
    setAccessibilityToast(false)
    setShowAccessibilityMode(false)
  }

  return (
    <SlideInModal
      className={classes.container}
      show={showAccessibilityMode}
      backButtonText={t('seatings_closeTicketList')}
      onBackClick={onBackClick}
    >
      <Header />
      <Filters t={t} />
      {placesCount ? <TicketsCountInfo placesCount={placesCount} /> : null}
      <TicketsList setForcedPopover={setForcedPopover} />
      {!chooseSeatMode ? <Footer /> : null}
    </SlideInModal>
  )
}

import * as React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import s from './summary-container.scss'
import {SummaryContainerProps} from './index'

export const SummaryContainer: React.FC<SummaryContainerProps> = ({inTickets, inAccessibilityMode, children}) => {
  const {isMobile} = useEnvironment()
  const mobileInTickets = isMobile && inTickets
  const mobileAccessibilityMode = isMobile && inAccessibilityMode

  return (
    <div className={classNames(s.container, {[s.stickyContainer]: mobileInTickets || mobileAccessibilityMode})}>
      {children}
    </div>
  )
}

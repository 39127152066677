import {getEventTicketLimitPerOrder} from '@wix/wix-events-commons-statics'
import {connect} from '../../../../../../../../commons/components/runtime-context/connect'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {TicketLimitReached as Presentation} from './ticket-limit-reached'
import {TicketLimitReachedRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions: {setError}}: DetailsPageAppProps): TicketLimitReachedRuntimeProps => ({
  ticketLimitPerOrder: getEventTicketLimitPerOrder(state.event),
  setError,
})

export const TicketLimitReached = connect<{}, TicketLimitReachedRuntimeProps>(mapRuntime)(Presentation)

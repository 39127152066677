import React from 'react'
import {SocialShare} from '../index'
import {Subtitle} from '../../subtitle'
import s from '../social-share.scss'
import {SocialShareWithTitleProps} from './index'

export const SocialShareWithTitle: React.FC<SocialShareWithTitleProps> = ({title, isVisible, t, page}) =>
  isVisible ? (
    <div>
      <Subtitle dataHook="social-share" text={title} />
      <div className={s.container}>
        <SocialShare t={t} page={page} />
      </div>
    </div>
  ) : null

import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {DropdownOptionProps} from 'wix-ui-tpa/Dropdown'
import {focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {DEFAULT_OPTION_ID} from '../../../../../selectors/seating'
import s from './filters.scss'
import {StyledFloatingDropdown} from './styled-dropdown'
import {VerticalDivider} from './vertical-divider'
import {FilterContainer} from './filter-container'
import {FiltersProps} from './index'

export const Filters = ({
  selectPrice,
  selectLocation,
  showAccessibilityMode,
  priceOptions,
  zoneOptions,
  selectedPriceOptionId,
  selectedPriceValue,
  selectedZoneOptionId,
  selectedZoneValue,
}: FiltersProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  React.useEffect(() => {
    selectPrice(null)
    selectLocation(null)
  }, [showAccessibilityMode, selectPrice, selectLocation])

  const handleSelectPrice = (selectedOption: DropdownOptionProps) => {
    selectPrice(selectedOption.id === DEFAULT_OPTION_ID ? null : selectedOption.id)
    focusElement({selector: hookToAttributeSelector(DH.SEATING_PRICE_FILTER)})
  }

  const handleSelectZone = (selectedOption: DropdownOptionProps) => {
    selectLocation(selectedOption.id === DEFAULT_OPTION_ID ? null : selectedOption.id)
    focusElement({selector: hookToAttributeSelector(DH.SEATING_ZONE_FILTER)})
  }

  return (
    <div className={classNames(s.container, {[s.mobileContainer]: isMobile})}>
      <FilterContainer>
        <StyledFloatingDropdown
          dataHook={DH.SEATING_PRICE_FILTER}
          label={t('seatings_filters_priceLabel')}
          value={selectedPriceOptionId}
          ariaLabel={t('seatings_filters_priceAriaLabel', {value: selectedPriceValue})}
          options={priceOptions}
          onChange={handleSelectPrice}
        />
      </FilterContainer>
      {isMobile ? null : <VerticalDivider />}
      <FilterContainer>
        <StyledFloatingDropdown
          dataHook={DH.SEATING_ZONE_FILTER}
          value={selectedZoneOptionId}
          label={t('seatings_filters_zoneLabel')}
          ariaLabel={t('seatings_filters_zoneAriaLabel', {value: selectedZoneValue})}
          options={zoneOptions}
          onChange={handleSelectZone}
        />
      </FilterContainer>
    </div>
  )
}

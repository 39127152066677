import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {LabelWithValue} from '../../../common/label-with-value'
import {getPlaceInfoForOrderSummary} from '../../../../selectors/order-summary'
import s from './place.scss'
import {PlaceProps} from './index'

export const Place = ({seatingPlan, ticket, seatId}: PlaceProps) => {
  const {t} = useTranslation()
  const {sector, element, area, table, place} = getPlaceInfoForOrderSummary(seatId, seatingPlan)

  return (
    <div className={s.container}>
      <div className={s.placeInfo}>
        <LabelWithValue label={t('seatings_sector')} value={sector.title} />
        {area ? (
          <LabelWithValue label={t('seatings_area')} value={element.title} />
        ) : (
          <>
            <LabelWithValue label={table ? t('seatings_table') : t('seatings_row')} value={element.title} />
            <LabelWithValue label={t('seatings_seat')} value={place.label} />
          </>
        )}
      </div>
      <div className={s.ticketInfo}>
        <span>{ticket.name}</span>
        <span className={s.ticketPrice}>{getFormattedMoney(ticket.price)}</span>
      </div>
    </div>
  )
}

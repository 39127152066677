import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Subtitle} from '../../subtitle'
import c from '../../classnames.scss'
import s from './schedule.scss'
import {List} from './list'
import {Footer} from './footer'
import {ScheduleProps} from './index'

export const Schedule = ({t, mobile, items, total, navigateToSchedulePage, scheduleTitleText}: ScheduleProps) => (
  <div className={!mobile ? s.container : ''} data-hook={DH.SCHEDULE}>
    <Subtitle dataHook={DH.SCHEDULE_SUBTITLE} text={scheduleTitleText} />
    <List t={t} items={items} mobile={mobile} />
    <Footer total={total} t={t} mobile={mobile} navigateToSchedulePage={navigateToSchedulePage} />
    {mobile && <div className={classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor)} />}
  </div>
)

import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Tooltip} from 'wix-ui-tpa/Tooltip'
import {InnerSummary} from '../inner-summary'
import {ButtonStyle} from '../button-style'
import {classes} from './summary.st.css'
import {SummaryProps} from './index'

export const Summary = ({
  invoice,
  selectedTickets,
  withoutBreakdown,
  inTickets,
  onCheckoutClick,
  eventPreview,
}: SummaryProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  return (
    <div className={classes.container}>
      {selectedTickets || inTickets ? (
        <InnerSummary invoice={invoice} withoutBreakdown={withoutBreakdown} selectedTickets={selectedTickets} />
      ) : (
        t('seatings_selectTickets')
      )}
      <CheckoutButton
        onCheckoutClick={onCheckoutClick}
        previewCheckout={eventPreview}
        isMobile={isMobile}
        inTickets={inTickets}
        selectedTickets={selectedTickets}
        t={t}
      />
    </div>
  )
}

const CheckoutButton = ({previewCheckout, onCheckoutClick, inTickets, selectedTickets, isMobile, t}) => {
  if (!onCheckoutClick) {
    return null
  }

  const getCheckoutText = () => {
    if (!isMobile) {
      return t('seatings_desktopCheckout')
    }

    return inTickets ? t('seatings_continueToCheckout') : t('seatings_checkout', {count: selectedTickets})
  }

  const button = (
    <ButtonStyle
      key="checkout-button"
      text={getCheckoutText()}
      dataHook={inTickets ? DH.CHECKOUT : DH.REVIEW_AND_CHECKOUT}
      disabled={!selectedTickets || previewCheckout}
      onClick={onCheckoutClick}
    />
  )

  if (previewCheckout) {
    return (
      <Tooltip content={t('seatingCheckoutPreviewTooltip')} fluid={true}>
        {button}
      </Tooltip>
    )
  }

  return button
}

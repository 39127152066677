import * as React from 'react'
import {focusElement, addCircularTabFocus, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {Tickets} from '../../tickets'
import {AccessibilityMode} from '../../accessibility-mode'
import {TicketDescription} from '../../ticket-description'
import s from './sidebar.scss'
import {SidebarProps} from './index'

export const Sidebar = ({
  showAccessibilityMode,
  onRemoveClick,
  onCheckoutClick,
  chooseSeatMode,
  placesInBasket,
  placesInBasketCount,
  setForcedPopover,
  selectedSeatForDescription,
  setSelectedSeatForDescription,
  setAccessibilityToast,
}: SidebarProps) => {
  React.useEffect(() => {
    if (!showAccessibilityMode && !selectedSeatForDescription) {
      focusElement({selector: hookToAttributeSelector(DH.EVENT_INFO)})
      addCircularTabFocus(
        hookToAttributeSelector(placesInBasketCount ? DH.REMOVE_TICKET : DH.ACCESSIBILITY_MODE_BUTTON),
        hookToAttributeSelector(DH.CLOSE_BUTTON),
      )
    }
  }, [showAccessibilityMode, placesInBasketCount, selectedSeatForDescription])

  return (
    <div className={classNames(s.sidebar, {[s.accessibilityModeSidebar]: showAccessibilityMode})}>
      {showAccessibilityMode ? (
        <AccessibilityMode setForcedPopover={setForcedPopover} setAccessibilityToast={setAccessibilityToast} />
      ) : null}
      {!showAccessibilityMode ? (
        <Tickets
          placesInBasketCount={placesInBasketCount}
          placesInBasket={placesInBasket}
          showTickets={true}
          onRemoveClick={onRemoveClick}
          onCheckoutClick={onCheckoutClick}
          onTicketMoreInfoClick={setSelectedSeatForDescription}
          chooseSeatMode={chooseSeatMode}
          setForcedPopover={setForcedPopover}
          selectedSeatForDescription={selectedSeatForDescription}
        />
      ) : null}
      {!showAccessibilityMode ? (
        <TicketDescription
          selectedSeatForDescription={selectedSeatForDescription}
          onClose={() => setSelectedSeatForDescription(null)}
        />
      ) : null}
    </div>
  )
}

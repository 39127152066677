import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../../../../commons/selectors/environment'
import {getComponentSettings} from '../../../../../selectors/component'
import {getContentAlignment} from '../../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {PriceOwnProps, PriceRuntimeProps} from './interfaces'
import {Price as Presentation} from './price'

export const mapRuntime = ({state}: DetailsPageAppProps): PriceRuntimeProps => {
  const settings = getComponentSettings(state)

  return {
    isMobile: isMobile(state),
    contentAlignment: getContentAlignment(settings),
  }
}

export const Price = withTranslation()(connect<PriceOwnProps, PriceRuntimeProps>(mapRuntime)(Presentation))
export * from './interfaces'
